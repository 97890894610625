import { Grid, makeStyles } from "@material-ui/core"
import React, { memo, useState } from "react"
import { ContentfulAssessments, ContentfulDragAndDrop } from "types/graphql-types"
import ContentfulRichText, { ContentfulRichTextType } from "../../contentful/contentful-rich-text";
import { DndContext } from "@dnd-kit/core";
import { Droppable } from "../drag-and-drop";
import { graphql } from "gatsby";
import clsx from 'clsx';
import { useDispatch } from "react-redux";
import { correctAnswer, wrongAnswer } from "src/state/features/lms/lmsSlice";
import Feedback from "../feedback/feedback";
import { CustomLayout } from "../layout";
import { IHeaderProps } from "../layout/custom-layout";


const useStyles = makeStyles(theme => ({
    dropContainer: {
        position: "relative",
        flex: "1 1 auto",
        border: "0.225rem solid #989898",
        width: '150px',
        height: '150px',
        borderRadius: '100%',
        textAlign: "center",
        boxSizing: "border-box",
        overflow: "hidden",
        zIndex: 1,
        boxShadow: "0.25rem 0.25rem 0.375rem rgba(0,0,0,0.16)",
        touchAction: 'none',
        margin: "30px auto"
    },
    dragContainer: {
        position: "relative",
        flex: "1 1 auto",
        display: "flex",
        border: "0.225rem solid " + theme.palette.primary.main,
        width: '150px',
        height: '150px',
        borderRadius: '100%',
        textAlign: "center",
        boxSizing: "border-box",
        overflow: "hidden",
        zIndex: 100,
        boxShadow: "0.25rem 0.25rem 0.375rem rgba(0,0,0,0.16)",
        touchAction: 'none',
        margin: "0 auto"
    },
    overDropContainer: {
        backgroundColor: theme.palette.primary.main,
        zIndex: 0
    },
    image: {
        position: "absolute",
        top: "50%",
        left: "50%",
        width: "auto",
        height: "80%",
        transform: "translate(-50%, -50%)",
    },
    text: {
        position: "absolute",
        top: "50%",
        left: "50%",
        margin: "auto",
        fontFamily: "'Open Sans', Arial, Helvetica, sans-serif",
        fontSize: "1.3375rem",
        fontWeight: 500,
        transform: "translate(-50%, -50%)",
    },
    dragAndDropContainer: {
        marginTop: "4rem"
    }
}))

interface IProps {
    data: ContentfulDragAndDrop
    handleNextSection: () => void
    debriefTitle: string
    headerProps: IHeaderProps
}

const DragAndDrop = ({ data, handleNextSection, headerProps, debriefTitle }: IProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [] = useState(0);
    const [showFeedback] = useState(false);

    const { overview, firstContainer, secondContainer, feedback } = data;
    const containers = [firstContainer, secondContainer];

    const DragAndDropImage = memo(({ src }: { src: string | null | undefined }) => (
        <>{src && <img className={classes.image} src={src} />}</>
    ));

    const DragAndDropText = memo(({ label }: { label: string | null | undefined }) => (
        <>{label && <strong className={classes.text}>{label}</strong>}</>
    ));

    function handleDragEnd(event: { over: any, active: any }) {
        const { over, active } = event;

        if (over) {
            const isCorrect = over.id === active.id;
            if (isCorrect) dispatch(correctAnswer());
            else dispatch(wrongAnswer());

            // if (list && step === list?.length - 1) {
            //     if (setTitle) setTitle();
            //     setShowFeedback(true);
            // } else {
            //     setStep(step + 1);
            // }
        }
    }

    const debriefHeaderProps = {
        ...headerProps,
        title: debriefTitle || headerProps.title
    }

    if (showFeedback) {
        return (
            <Feedback
                headerProps={debriefHeaderProps}
                handleNextSection={handleNextSection}
                data={feedback}
            />
        )
    }

    return (
        <DndContext onDragEnd={handleDragEnd}>
            <CustomLayout
                headerProps={headerProps}
            >
                <Grid item xs={12}>
                    <ContentfulRichText {...overview as unknown as ContentfulRichTextType} />
                </Grid>

                <Grid container style={{ overflow: 'hidden' }}>
                    <Grid item xs={12} className={classes.dragAndDropContainer}>
                        {/* {draggingItem &&
                        <Draggable id={draggingItem.correctAnswer?.id}>
                            {({ attributes, listeners }) => (
                                <div className={classes.dragContainer} {...attributes} {...listeners}>
                                    {draggingItem.image ?
                                        <DragAndDropImage src={draggingItem?.image.file?.url} />
                                        :
                                        <DragAndDropText label={draggingItem?.text} />
                                    }
                                </div>
                            )}
                        </Draggable>
                    } */}

                    </Grid>
                    {containers.map(container => {
                        return (
                            <Grid item xs={6}>
                                <Droppable key={container?.id} id={container?.id}>
                                    {({ isOver }) => (
                                        <div className={clsx({
                                            [classes.dropContainer]: true,
                                            [classes.overDropContainer]: isOver
                                        })}>
                                            {container?.image ?
                                                <DragAndDropImage src={container?.image.file?.url} />
                                                :
                                                <DragAndDropText label={container?.text} />
                                            }
                                        </div>
                                    )}
                                </Droppable>
                            </Grid>
                        )
                    })}
                </Grid>
            </CustomLayout>
        </DndContext>
    )
}


export const dragAndDropFragment = graphql`
fragment dragAndDropFragment on ContentfulDragAndDrop {
    id
    title
    feedback {
      raw
      references {
        __typename
        ... on ContentfulAsset {
          ...assetFragment
        }
      }
    }
    overview {
      raw
      references {
        __typename
        ... on ContentfulAsset {
          ...assetFragment
        }
      }
    }
    firstContainer {
      title
      text
      id
      image {
        id
        contentful_id
        file {
          url
          contentType
        }
        fixed {
          ...GatsbyContentfulFixed
        }
        fluid {
          ...GatsbyContentfulFluid
        }
      }
    }
    secondContainer {
      text
      title
      id
      image {
        id
        contentful_id
        file {
          url
          contentType
        }
        fixed {
          ...GatsbyContentfulFixed
        }
        fluid {
          ...GatsbyContentfulFluid
        }
      }
    }
  }  
`

export default DragAndDrop;
