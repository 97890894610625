import React, { ChangeEvent } from 'react';
import { makeStyles, FormControlLabel } from '@material-ui/core';
import clsx from 'clsx';
import { RadioButton } from '.';

const useStyles = makeStyles(theme => ({
    customControl: {
        margin: "0",
        padding: "0.625rem 0.75rem",
        color: "#DB5205",
        backgroundColor: "transparent",
        border: "0.0625rem solid #DB5205",
    },
    customSecondaryControl: {
        margin: "0",
        padding: "0.625rem 0.75rem",
        color: "#DB5205",
        backgroundColor: "transparent",
        border: "0.0625rem solid #DB5205",
    },
    labelText: {
        color: "#DB5205",
        fontSize: "0.8125rem",
        fontWeight: 500,
        textAlign:'center'
    },
    labelTextChecked: {
        color: "#fff",
    },
    checked: {
        color: "#fff",
        backgroundColor: "#DB5205",
        border: "0.0625rem solid #DB5205"
    },
    likertControl: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "2.625rem",
        margin: "0",
        color: "#DB5205",
        backgroundColor: "transparent",
        border: "0.0625rem solid #DB5205",
    },
    likertChecked: {
        backgroundColor: "#DB5205",
        border: "0.0625rem solid #DB5205"
    },
    label: {
        color: "#fff"
    }
}))

interface IProps {
    value?: string | number
    label: string
    name: string
    secondary?: boolean
    checked?: boolean
    radioLabel?: string
    likert?: boolean
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void
}

const SurveyControlLabel: React.FC<IProps> = ({ value, label, secondary, checked, onChange, name, radioLabel ,likert}) => {
    const classes = useStyles();

    if (!!likert) return (
        <FormControlLabel
            value={value}
            labelPlacement="bottom"
            control={
                <RadioButton
                    name={name}
                    radioLabel={radioLabel}
                    checked={checked}
                    onChange={onChange}
                />
            }
            label={<span className={
                clsx({
                    [classes.labelText]: classes,
                    [classes.labelTextChecked]: checked
                })
            }>{value}</span>}
            aria-label={label}
            className={
                clsx({
                    [classes.likertControl]: !secondary,
                    [classes.customSecondaryControl]: secondary,
                    [classes.likertChecked]: checked
                })
            }
        />
    )


    return (
        <FormControlLabel
            value={value}
            labelPlacement="bottom"
            control={
                <RadioButton
                    name={name}
                    radioLabel={radioLabel}
                    checked={checked}
                    onChange={onChange}
                />
            }
            label={<span className={
                clsx({
                    [classes.labelText]: classes,
                    [classes.labelTextChecked]: checked
                })
            }>{value}</span>}
            aria-label={label}
            className={
                clsx({
                    [classes.likertControl]: !secondary,
                    [classes.customSecondaryControl]: secondary,
                    [classes.likertChecked]: checked
                })
            }
        />
    )
}

export default SurveyControlLabel;