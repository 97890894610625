import { useDroppable } from '@dnd-kit/core';
import React from 'react';

interface InjectedProps {
    isOver: boolean
  }

interface IProps {
    children(props:InjectedProps): JSX.Element;
    id: any
}

const Droppable = ({ children, id}: IProps) => {
    const { isOver, setNodeRef } = useDroppable({
        id: id,
    });

    return (
        <div ref={setNodeRef}> 
            {children({isOver})}
        </div>
    );
}

export default Droppable;