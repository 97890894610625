import React, { Fragment, ChangeEvent } from 'react';
import { makeStyles, FormControl, RadioGroup } from '@material-ui/core';
import { RadioOption } from 'src/type/globals';
import QuestionLabel from 'src/components/labels/question-label';
import { ControlLabel } from '.';
import _ from 'lodash';
/**
 * Likert Container
 * This is the container for a Likert scale type question in the survey
 * 
 */


const useStyles = makeStyles(theme => ({
    likertRoot: {
        width: "100%",
        margin: "2.5rem 0 3.75rem 0",
    },
    inputsWrapper: {
        flexDirection: "row",
        flexWrap: "nowrap",
        border: "0.0625rem solid #DB5205",
        borderRadius: "0.3175rem",
        boxShadow: "0 0 0 0.0625rem #DB5205 inset",
        overflow: "hidden",
    },
    labels: {
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "space-between",
        marginTop: "0.3125rem",
        color: "#707070",
        fontFamily: "Roboto Condensed",
    },
}))

interface IProps {
    currentAnswer: any
    handleRadioChange: (event: ChangeEvent<HTMLInputElement>) => void
    startScale: number
    endScale: number
    startLabel: string | null | undefined
    endLabel: string | null | undefined
    id?: string
    name: string
}

const LikertContainer: React.FC<IProps> = ({ handleRadioChange, currentAnswer, startScale, endScale, startLabel, endLabel, id, name }) => {
    const classes = useStyles();
    const scaleArray = _.range(startScale, endScale + 1, 1);

    function handleChange(e: ChangeEvent<HTMLInputElement>) {
        handleRadioChange(e);
    }

    return (
        <Fragment>
            <FormControl component="div" className={classes.likertRoot}>
                <RadioGroup
                    className={classes.inputsWrapper}
                    name={id}
                >
                    {scaleArray.map((d: number, index: number) => {
                        return (
                            <Fragment key={index}>
                                <ControlLabel
                                    likert
                                    name={name}
                                    value={d}
                                    label={`${d}`}
                                    radioLabel={`${d}`}
                                    checked={currentAnswer == d}
                                    onChange={handleChange}
                                />
                            </Fragment>
                        )
                    })}
                </RadioGroup>
                <div className={classes.labels}>
                    <span>{startLabel && startLabel}</span>
                    <span>{endLabel && endLabel}</span>
                </div>
            </FormControl>
        </Fragment>
    )
}

export default LikertContainer;