import React, { Fragment, ChangeEvent } from 'react';
import { makeStyles, TextField } from '@material-ui/core';


const useStyles = makeStyles(theme => ({
    root: {
    },
}))

interface IProps {
    handleInputChange: (event: ChangeEvent<HTMLInputElement>) => void
    id: string
    classes?: any
    value: string | number
    visible?: boolean
    label?: string
    type?: string
    required?: boolean
    errorText?: string
    error?: boolean
    touched?: boolean
    handleBlur?: any
}

const InputField: React.FC<IProps> = ({ value, id, classes, handleInputChange, errorText, error=false, visible = true, label = "input", type, required = false }) => {
    //const classes = useStyles();

    return (
        <Fragment>
            {visible &&
                <TextField
                    error={error}
                    required={required}
                    type={type}
                    id={id}
                    fullWidth
                    margin="none"
                    label={label}
                    value={value}
                    helperText={errorText}
                    classes={classes}
                    onChange={handleInputChange}
                />
            }
        </Fragment>
    )
}

export default InputField;