import { Grid } from "@material-ui/core"
import React, { ChangeEvent, useEffect, useState } from "react"
import { ContentfulLikertAssessment } from "types/graphql-types"
import StyledButton, { ButtonType } from "src/components/buttons/styled-button";
import { LikertContainer } from "src/components/form";
import ContentfulRichText, { ContentfulRichTextType } from "../../contentful/contentful-rich-text";
import { graphql } from "gatsby";
import { useDispatch } from "react-redux";
import { setSurveyDataAction, SurveySectionData } from "src/state/features/survey/surveySlice";
import { useSurveyDataGetter } from "src/hooks/useDataGetter";
import { CustomLayout } from "../layout";
import { IFooterProps, IHeaderProps } from "../layout/custom-layout";

export type LikertData = {
    value: number | null
}

interface IProps {
    data: ContentfulLikertAssessment
    section: string
    handleNextSection: () => void
    headerProps: IHeaderProps
}
export type ContentfulLikertAssessmentType =
    ({ __typename: 'ContentfulLikertAssessment' } & ContentfulLikertAssessment);


const Likert = ({ data, handleNextSection, section, headerProps }: IProps) => {
    const dispatch = useDispatch();
    const { question, startingScale, endingScale, startingLabel, endingLabel, id, __typename, name, optional } = data as ContentfulLikertAssessmentType;

    const surveySectionData = useSurveyDataGetter(`${section}.${id}`);
    const componentData: LikertData = surveySectionData ? surveySectionData.data as LikertData : { value: null }

    const [answer, setAnswer] = useState(componentData.value);

    useEffect(() => { setAnswer(null) }, [id])

    function handleAnswerSelect(e: ChangeEvent<HTMLInputElement>) {
        setAnswer(parseInt(e.target.value));
    }

    function handleSubmit() {
        const value = {
            name: name,
            type: __typename,
            data: {
                value: answer
            }
        } as SurveySectionData
        dispatch(setSurveyDataAction({ name: `${section}.${id}`, value: value }))
        handleNextSection();
    }
    const footerProps: IFooterProps = {
        submitButton: {
            onClickHandler: handleSubmit,
            type: ButtonType.CONTINUE,
            isDisabled: answer === null
        },
        allowSkip: !!optional,
        skipButton: {
            onClickHandler: handleNextSection
        }
    }
    return (
        <CustomLayout
            headerProps={headerProps}
            footerProps={footerProps}
        >
            {question &&
                <Grid item xs={12}>
                    <ContentfulRichText {...question as unknown as ContentfulRichTextType} />
                </Grid>
            }
            <Grid item xs={12}>
                <LikertContainer
                    id={id}
                    name={name!}
                    handleRadioChange={handleAnswerSelect}
                    currentAnswer={answer}
                    startScale={startingScale!}
                    endScale={endingScale!}
                    startLabel={startingLabel}
                    endLabel={endingLabel}
                />
            </Grid>
        </CustomLayout>
    )
}

export const likertFragment = graphql`
fragment likertFragment on ContentfulLikertAssessment {
    id
    __typename
    name
    title
    optional
    startingScale
    startingLabel
    endingScale
    endingLabel
    question {
      raw
      references {
        __typename
        ... on ContentfulAsset {
            ...assetFragment
        }
      }
    }
  }
  `


export default Likert;