import React, { Fragment, ChangeEvent } from 'react';
import { makeStyles, FormControl } from '@material-ui/core';
import QuestionLabel from 'src/components/labels/question-label';
import { RadioOption } from 'src/type/globals';
import { ControlLabel } from '.';

/**
 * Radio Container
 * This is the container for a Radio type question in the survey
 * 
 */

const useStyles = makeStyles(theme => ({
    formGroup: {
        width: "100%",
        marginBottom: "2.5rem",
        border: "0.0625rem solid #DB5205",
        borderRadius: "0.3125rem",
        boxShadow: "0 0 0 0.0625rem #DB5205 inset",
        overflow: "hidden",
    },
    textField: {
        '& .MuiTextField-root': {
            display: "flex",
            width: "auto",
            margin: "1.25rem 0.75rem 0 0.75rem",
            padding: 0,

            '&:after': {
                content: "''",
                position: "absolute",
                display: "block",
                bottom: 0,
                left: "-0.75rem",
                width: "calc(100% + 1.5rem)",
                height: "0.0625rem",
                background: "#DB5205",
            }
        }
    }
}))

interface IProps {
    currentAnswer: any
    handleRadioChange: (event: ChangeEvent<HTMLInputElement>) => void
    options: RadioOption[] | undefined
    name: string
}

const RadioContainer: React.FC<IProps> = ({ handleRadioChange, currentAnswer, options, name }) => {
    const classes = useStyles();

    function handleChange(e: ChangeEvent<HTMLInputElement>, answer: any) {
        handleRadioChange(e);
    }

    return (
        <Fragment>
            <FormControl component="div" className={classes.formGroup}>
                {options && options.map((d: any, index: number) => {
                    return (
                        <Fragment key={index}>
                            <ControlLabel
                                onChange={(e) => handleChange(e, d)}
                                name={name}
                                value={d.value}
                                label={d.label}
                                checked={currentAnswer === d.value}
                            />
                        </Fragment>
                    )
                })}
            </FormControl>
        </Fragment>
    )
}

export default RadioContainer;