import { SurveySectionData } from "src/state/features/survey/surveySlice";
import { getSurveyDataByName } from "src/state/features/survey/surveySelector";
import { useParamSelector } from "./useParamSelector";

export function useSurveyDataGetter(name: string) {
    const surveyData = useParamSelector(getSurveyDataByName, `${name}`) as SurveySectionData | null;
    return surveyData;
}

export function useCourseDataGetter(name:string){
    return ""
}