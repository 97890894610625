import React, { Fragment, ChangeEvent, useState } from 'react';
import { makeStyles, FormControl } from '@material-ui/core';
import { RadioOption } from 'src/type/globals';
import { ControlLabel } from '.';
import InputField from './input-field';
import { MultipleChoiceAssessmentType, OptionType } from '../assessments/multiple-choice/multiple-choice';

/**
 * Radio Container
 * This is the container for a Radio type question in the survey
 * 
 */

const useStyles = makeStyles(() => ({
    formGroup: {
        width: "100%",
        border: "0.0625rem solid #DB5205",
        borderRadius: "0.3125rem",
        boxShadow: "0 0 0 0.0625rem #DB5205 inset",
        overflow: "hidden",
    },
    formGroupSecond: {
        width: "auto",
        margin: "0.625rem",
        borderRadius: "0.3125rem",
        boxShadow: "0 0 0 0.0625rem #DB5205 inset",

        '&:after': {
            content: "''",
            position: "absolute",
            bottom: "-0.6875rem",
            left: "-0.6875rem",
            width: "calc(100% + 1.375rem)",
            height: "0.0625rem",
            background: "#DB5205",
        }
    },
    textField: {
        '& .MuiTextField-root': {
            display: "flex",
            width: "auto",
            margin: "1.25rem 0.75rem 0 0.75rem",
            padding: 0,

            '&:after': {
                content: "''",
                position: "absolute",
                display: "block",
                bottom: 0,
                left: "-0.75rem",
                width: "calc(100% + 1.5rem)",
                height: "0.0625rem",
                background: "#DB5205",
            }
        }
    }
}))


interface IProps {
    handleAnswerSelect: (event: ChangeEvent<HTMLInputElement>) => void
    handleInputChange?: (event: ChangeEvent<HTMLInputElement>) => void
    options: RadioOption[] | undefined
    name: string
    textAnswer?: string
}

const NestedRadioContainer: React.FC<IProps> = ({ handleAnswerSelect, handleInputChange, textAnswer, options, name }) => {
    const classes = useStyles();
    const [selectedValue, setSelectedValue] = useState("");

    function handleChange(e: ChangeEvent<HTMLInputElement>, answer: RadioOption) {
        setSelectedValue(answer.value)
        handleAnswerSelect(e);
    }

    return (
        <Fragment>
            <FormControl component="div" className={classes.formGroup}>
                {options && options.map((d: RadioOption, index: number) => {
                    return (
                        <Fragment key={index}>
                            <ControlLabel
                                onChange={(e) => handleChange(e, d)}
                                name={name}
                                value={d.value}
                                label={d.label}
                                checked={selectedValue === d.value}
                            />
                            {
                                (selectedValue === d.value) && (d.typename === "TextInput") &&
                                <InputField
                                    label="Input: "
                                    value={textAnswer || ""}
                                    id={d.label}
                                    handleInputChange={handleInputChange ? handleInputChange : ()=>{}}
                                />
                            }
                            {
                                (selectedValue === d.value) && (d.typename === "Container") &&
                                <div className={classes.formGroupSecond}>
                                    <NestedRadioContainer
                                        name={d.label}
                                        handleInputChange={handleInputChange}
                                        textAnswer={textAnswer}
                                        handleAnswerSelect={handleAnswerSelect}
                                        options={d.containerOptions?.map((o: any) => {
                                            const option = o as MultipleChoiceAssessmentType;
                                            return {
                                                id: option?.id,
                                                typename: OptionType[option?.__typename as string],
                                                label: option?.label,
                                                value: option?.label,
                                                containerOptions: option.__typename == "ContentfulMultipleChoiceQuestionOptionContainer" ? option?.listOfOptions : null
                                            } as RadioOption
                                        })}
                                    />
                                </div>
                            }
                        </Fragment>
                    )
                })}
            </FormControl>
        </Fragment>
    )
}

export default NestedRadioContainer;