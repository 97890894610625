import React, { Fragment, ChangeEvent, useState } from 'react';
import { makeStyles, FormControl, Select, MenuItem } from '@material-ui/core';
import { RadioOption } from 'src/type/globals';

/**
 * Radio Container
 * This is the container for a Radio type question in the survey
 * 
 */

const useStyles = makeStyles((theme) => ({
    formControl: {
        display: "block",
        borderColor: theme.palette.primary.main,
        borderRadius: "4px",
        marginBottom: "2.5rem"
    },
    select: {
        height: "auto",
        fontSize: "1rem",
        lineHeight: "1em",
    },
    icon: {
        color: theme.palette.primary.main,
        fontSize: "1.5rem",
    },
}));

interface IProps {
    handleAnswerSelect: (event: ChangeEvent<HTMLInputElement>) => void
    handleInputChange?: (event: ChangeEvent<HTMLInputElement>) => void
    options: (RadioOption | undefined)[] | undefined
    name: string
    textAnswer?: string
}

const DropDown: React.FC<IProps> = ({ handleAnswerSelect, options }) => {
    const classes = useStyles();
    const [selectedValue, setSelectedValue] = useState("");

    function handleChange(e) {
        setSelectedValue(e.target.value)
        handleAnswerSelect(e);
    }

    return (
        <Fragment>
            <FormControl component="div" className={classes.formControl}>
                <Select 
                    fullWidth
                    variant="outlined"
                    value={selectedValue}
                    onChange={handleChange}
                    classes={{
                        select: classes.select,
                        icon: classes.icon,
                    }}
                >
                    <MenuItem key="dropdown-null" value=""></MenuItem>
                    {options && options.map(
                        (d: RadioOption | undefined) => d ? <MenuItem key={"dropdown-"+d.value} value={d.value}>{d.label}</MenuItem> : undefined
                    )}
                </Select>
            </FormControl>
        </Fragment>
    )
}

export default DropDown;