import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import StyledButton, { ButtonType } from "src/components/buttons/styled-button";
import ContentfulRichText, { ContentfulRichTextType } from "src/contentful/contentful-rich-text";
import { ContentfulMultipleChoiceQuestionCorrectDebrief, ContentfulMultipleChoiceQuestionWrongDebrief } from "types/graphql-types";
import { CustomLayout } from "../layout";
import { IFooterProps, IHeaderProps } from "../layout/custom-layout";

const useStyles = makeStyles(theme => ({
    feedbackContainer: {
    },
    feedbackButton: {
        marginTop: 'auto'
    }
}))

interface IProps {
    data: ContentfulMultipleChoiceQuestionCorrectDebrief | ContentfulMultipleChoiceQuestionWrongDebrief | null | undefined
    handleNextSection: () => void
    headerProps: IHeaderProps
}

const Feedback = ({ data, handleNextSection, headerProps }: IProps) => {
    const classes = useStyles();

    const footerProps: IFooterProps = {
        submitButton: {
            onClickHandler: handleNextSection,
            type: ButtonType.CONTINUE,
            isDisabled: false
        },
        allowSkip: false
    }

    return (
        <CustomLayout
            headerProps={headerProps}
            footerProps={footerProps}
        >
            <Grid container spacing={0} className={classes.feedbackContainer}>
                {data &&
                    <Grid item xs={12} >
                        <ContentfulRichText {...data as unknown as ContentfulRichTextType} />
                    </Grid>
                }
            </Grid>
        </CustomLayout>
    )
}

export default Feedback;