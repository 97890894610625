import { Grid, makeStyles } from "@material-ui/core"
import React, { ChangeEvent, useEffect, useState } from "react"
import { RadioOption } from "src/type/globals";
import { ContentfulMultipleChoiceAssessmentOption, ContentfulMultipleChoiceQuestion, ContentfulMultipleChoiceQuestionOptionContainer, ContentfulMultipleChoiceQuestionOptionSelectable, ContentfulMultipleChoiceQuestionOptionTextInput } from "types/graphql-types"
import StyledButton, { ButtonType } from "src/components/buttons/styled-button";
import { useDispatch } from "react-redux";
import { NestedRadioContainer } from "src/components/form";
import ContentfulRichText, { ContentfulRichTextType } from "src/contentful/contentful-rich-text";
import { graphql } from "gatsby";
import { setSurveyDataAction, SurveySectionData } from "src/state/features/survey/surveySlice";
import { useSurveyDataGetter } from "src/hooks/useDataGetter";
import DropDown from "src/components/form/dropdown";
import { CustomLayout } from "src/components/layout";
import { IFooterProps, IHeaderProps } from "src/components/layout/custom-layout";

const useStyles = makeStyles(() => ({
  container: {
  },
  button: {
    marginTop: "auto"
  },
  question: {
  },
  nestedRadioContainer: {
    marginBottom: "2.5rem"
  }
}))
interface KeyValue {
  [key: string]: any
}

export const OptionType: KeyValue = {
  "ContentfulMultipleChoiceAssessmentOption": "Assessment",
  "ContentfulMultipleChoiceQuestionOptionSelectable": "Selectable",
  "ContentfulMultipleChoiceQuestionOptionTextInput": "TextInput",
  "ContentfulMultipleChoiceQuestionOptionContainer": "Container"
}

export type MultipleChoiceData = {
  radioAnswer: string,
  textAnswer: string
}

interface IProps {
  data: ContentfulMultipleChoiceQuestion
  section: string
  handleNextSection: () => void
  setTitle?: () => void
  headerProps: IHeaderProps
}

export type MultipleChoiceAssessmentType =
  ({ __typename: 'ContentfulMultipleChoiceAssessmentOption' } & ContentfulMultipleChoiceAssessmentOption)
  | ({ __typename: 'ContentfulMultipleChoiceQuestionOptionSelectable' } & ContentfulMultipleChoiceQuestionOptionSelectable)
  | ({ __typename: 'ContentfulMultipleChoiceQuestionOptionTextInput' } & ContentfulMultipleChoiceQuestionOptionTextInput)
  | ({ __typename: 'ContentfulMultipleChoiceQuestionOptionContainer' } & ContentfulMultipleChoiceQuestionOptionContainer)

export type ContentfulMultipleChoiceType =
  ({ __typename: 'ContentfulMultipleChoiceQuestion' } & ContentfulMultipleChoiceQuestion);


const MultipleChoice = ({ data, handleNextSection, section, headerProps }: IProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { id, __typename, name, optional, appearance, question, options } = data as ContentfulMultipleChoiceType;

  const surveySectionData = useSurveyDataGetter(`${section}.${id}`);
  const componentData: MultipleChoiceData = surveySectionData ? surveySectionData.data as MultipleChoiceData : { radioAnswer: "", textAnswer: "" }

  const [radioAnswer, setRadioAnswer] = useState(componentData.radioAnswer);
  const [textAnswer, setTextAnswer] = useState(componentData.textAnswer);

  useEffect(() => {
    setRadioAnswer("");
    setTextAnswer("");
  }, [])

  function handleSubmit() {
    const value = {
      name: name,
      type: __typename,
      data: {
        radioAnswer: radioAnswer,
        textAnswer: textAnswer
      }
    } as SurveySectionData
    handleNextSection();
    dispatch(setSurveyDataAction({ name: `${section}.${id}`, value: value }))
  }

  function handleAnswerSelect(e: ChangeEvent<HTMLInputElement>) {
    setTextAnswer("");
    setRadioAnswer(e.target.value);
  }

  function handleInputChange(e: ChangeEvent<HTMLInputElement>) {
    setTextAnswer(e.target.value);
  }
  const footerProps: IFooterProps = {
    submitButton: {
      onClickHandler: handleSubmit,
      type: ButtonType.CONTINUE,
      isDisabled: radioAnswer === ""
    },
    allowSkip: !!optional,
    skipButton: {
      onClickHandler: handleNextSection
    }
  }

  return (
    <CustomLayout
      headerProps={headerProps}
      footerProps={footerProps}
    >
      <Grid container spacing={0} alignItems="center" justify="center" className={classes.container}>
        {question &&
          <Grid item xs={12} className={classes.question} >
            <ContentfulRichText {...question as unknown as ContentfulRichTextType} />
          </Grid>
        }
        <Grid item xs={12} className={classes.nestedRadioContainer}>
          {appearance === "List" &&
            <NestedRadioContainer
              name={name!}
              textAnswer={textAnswer}
              handleInputChange={handleInputChange}
              handleAnswerSelect={handleAnswerSelect}
              options={options?.map(o => {
                const option = o as MultipleChoiceAssessmentType;
                return {
                  id: option?.id,
                  typename: OptionType[option?.__typename as string],
                  label: option?.label,
                  value: option?.label,
                  containerOptions: option.__typename == "ContentfulMultipleChoiceQuestionOptionContainer" ? option?.listOfOptions : null
                } as RadioOption
              })}
            />
          }
          {appearance === "Dropdown" &&
            <DropDown
              name={name!}
              handleAnswerSelect={handleAnswerSelect}
              options={options?.map(o => {
                const option = o as MultipleChoiceAssessmentType;
                if (OptionType[option?.__typename as string] === "Selectable")
                  return {
                    id: option?.id,
                    typename: OptionType[option?.__typename as string],
                    label: option?.label,
                    value: option?.label,
                    containerOptions: null
                  } as RadioOption
              })}
            />
          }
        </Grid>
      </Grid>
    </CustomLayout>
  )
}

export const multipleChoiceFragment = graphql`
fragment multipleChoiceFragment on ContentfulMultipleChoiceQuestion {
    id
    __typename
    name
    title
    optional
    appearance
    needsCorrectAnswer
    question {
      raw
      references {
        __typename
        ... on ContentfulAsset {
          ...assetFragment
        }
      }
    }
    options {
      __typename
      ... on ContentfulMultipleChoiceAssessmentOption {
        id
        __typename
        title
        label
        correctAnswer
      }
      ... on ContentfulMultipleChoiceQuestionOptionSelectable {
        id
        __typename
        title
        label
        isCorrectAnswer
      }
      ... on ContentfulMultipleChoiceQuestionOptionTextInput {
        id
        __typename
        title
        label
      }
      ... on ContentfulMultipleChoiceQuestionOptionContainer {
        id
        __typename
        title
        label
        listOfOptions {
          ... on ContentfulMultipleChoiceQuestionOptionSelectable {
            id
            __typename
            title
            label
          }
          ... on ContentfulMultipleChoiceQuestionOptionTextInput {
            id
            __typename
            title
            label
          }
        }
      }
    }
    correctDebrief {
      raw
      references {
        __typename
        ... on ContentfulAsset {
          ...assetFragment
        }
      }
    }
    wrongDebrief {
      raw
      references {
        __typename
        ... on ContentfulAsset {
          ...assetFragment
        }
      }
    }
  }  
  `

export default MultipleChoice;
