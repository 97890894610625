import { Grid, makeStyles, Radio } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { ContentfulAssessmentAnswerContainer, ContentfulMultiRadioSelectAssessment, Maybe } from "types/graphql-types"
import StyledButton, { ButtonType } from "src/components/buttons/styled-button";
import { useDispatch } from "react-redux";
import { correctAnswer, wrongAnswer } from "src/state/features/lms/lmsSlice";
import ContentfulRichText, { ContentfulRichTextType } from "src/contentful/contentful-rich-text";
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import { randomizeOptionList } from "src/utilities/contentful-utils";
import { graphql } from "gatsby";
import { CustomLayout } from "../layout";
import { IFooterProps, IHeaderProps } from "../layout/custom-layout";

const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
        justifyContent: "space-between"
    },
    container: {
        height: "80vh"
    },
    button: {
        marginTop: "auto"
    },
    question: {
    },
    columnHeading: {
        position: "relative",
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "flex-end",
        height: "8em",
        paddingRight: "5.9em",
        overflow: "hidden",
    },
    columnLabel: {
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        width: "3.9em",
        height: "3.38em",
        fontFamily: "'Roboto Condensed'",
        lineHeight: 1.1,
        boxSizing: "border-box",
        transform: "rotate(60deg)",
        transformOrigin: "right bottom",

        '&:before': {
            content: "''",
            position: "absolute",
            display: "block",
            top: "-1px",
            bottom: "-1px",
            right: "-2em",
            width: "12em",
            borderColor: "currentColor",
            borderStyle: "solid",
            borderWidth: "2px 0 0 0",
        },

        '&:first-child:before': {
            borderBottomWidth: "2px",
        }
    },
    columnLabelText: {
        flex: "0 0 auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        width: "7.5em",
        height: "100%",
        textAlign: "right",
    },
    formGroup: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0 2em 0 1em",

        '&:nth-child(2n + 2)': {
            background: "#eee",
        }
    },
    inputLabel: {
        display: "block",
        fontFamily: "'Roboto Condensed'",
        fontSize: "1.4em",
    },
    radioContainer: {
        display: "flex",
        alignItems: "center",
    },
    radioLabel: {
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "3.9em",
        height: "2.7em",

        '&:before': {
            content: "''",
            position: "absolute",
            display: "block",
            top: 0,
            bottom: 0,
            left: "-1px",
            right: "-1px",
            borderColor: "currentColor",
            borderStyle: "solid",
            borderWidth: "0 2px 0 0",
        },

        '&:first-child:before': {
            borderLeftWidth: "2px",
        }
    },
    radioIcon: {
        display: "block",
        width: "0.75em",
        height: "0.75em",
        background: "#fff",
        border: "0.125em solid #DB5205",
        borderRadius: "0.125em",
        boxSizing: "border-box",
    },
    radioIconChecked: {
        display: "block",
        width: "0.75em",
        height: "0.75em",
        background: "#DB5205",
        border: "0.125em solid #DB5205",
        borderRadius: "0.125em",
        boxShadow: "0 0 0 0.08em #fff inset",
        boxSizing: "border-box",
    },
    correctIcon: {
        alignItems: "center",
        color: "green"
    },
    incorrectIcon: {
        alignItems: "center",
        color: "red"
    },
    feedback: {
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "2em",
        height: "2.7em",
        marginRight: "-2em",
    },
}))

interface IProps {
    data: ContentfulMultiRadioSelectAssessment
    handleNextSection: () => void
    debriefTitle: string
    headerProps: IHeaderProps
}

const MultiRadioSelect = ({ data, handleNextSection, debriefTitle, headerProps }: IProps) => {
    const classes = useStyles();
    const { overview, feedback, firstOptionContainer, secondOptionContainer, id } = data;
    const dispatch = useDispatch();

    const combinedOptions = [...firstOptionContainer?.list!, ...secondOptionContainer?.list!];

    const [randomizedList, setList] = useState<Maybe<ContentfulAssessmentAnswerContainer>[]>([]);
    const [values, setValues] = useState<{ id: any, selectedAnswerId: any, isCorrect: boolean }[]>([]);
    const [showFeedback, setShowFeedback] = useState(false);

    useEffect(() => {
        const randomList = randomizeOptionList<ContentfulAssessmentAnswerContainer>(combinedOptions);
        setList(randomList);
    }, [])

    useEffect(() => {
        setShowFeedback(false);
        setValues([]);
    }, [id])

    function handleCheck(e: React.ChangeEvent<HTMLInputElement>) {
        let valuesCopy = [...values];
        const findItem = valuesCopy?.findIndex(x => x.id === e.target.id);
        const listItem = randomizedList?.find(x => x?.id === e.target.id);
        if (listItem) {
            if (findItem !== -1) valuesCopy = valuesCopy.filter(x => x.id !== e.target.id);
            if (listItem?.assessment_option_container) {
                valuesCopy?.push({ id: e.target.id, selectedAnswerId: e.target.value, isCorrect: listItem?.assessment_option_container[0]?.id === e.target.value });
                setValues(valuesCopy);
            }
        }
    }

    function handleSubmit() {
        if (showFeedback) {
            handleNextSection();
        }
        else {
            const correctanswerCount = values?.filter(x => x.isCorrect).length;
            if (correctanswerCount && values) {
                const assessmentScore = Math.round((correctanswerCount / values?.length) * 100);
                const passed = assessmentScore >= 75;
                if (passed) dispatch(correctAnswer());
                else dispatch(wrongAnswer());
            }
            if (feedback) {
                setShowFeedback(true);
            }
        }
    }

    function showFeedbackLabel(id: string | undefined) {
        if (id) {
            const isCorrect = values?.find(x => x.id === id)?.isCorrect;
            if (isCorrect) return <span className={classes.feedback}><DoneIcon className={classes.correctIcon} /></span>
            return <span className={classes.feedback}><CloseIcon className={classes.incorrectIcon} /></span>
        }
        return null;
    }

    const footerProps: IFooterProps = {
        submitButton: {
            onClickHandler: handleSubmit,
            type: ButtonType.CONTINUE,
            isDisabled: values?.length !== randomizedList.length
        },
        allowSkip: false,
    }

    const debriefHeaderProps = {
        ...headerProps,
        title: debriefTitle || headerProps.title
    }

    return (
        <CustomLayout
            headerProps={showFeedback ? debriefHeaderProps : headerProps}
            footerProps={footerProps}
        >
            <Grid container spacing={0} alignItems="center" justify="center">
                <Grid item xs={12} className={classes.question}>
                    {showFeedback ?
                        <ContentfulRichText {...feedback as unknown as ContentfulRichTextType} />
                        :
                        <ContentfulRichText {...overview as unknown as ContentfulRichTextType} />
                    }
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.columnHeading}>
                        <span className={classes.columnLabel} aria-hidden="true"><span className={classes.columnLabelText}>{firstOptionContainer?.text}</span></span>
                        <span className={classes.columnLabel} aria-hidden="true"><span className={classes.columnLabelText}>{secondOptionContainer?.text}</span></span>
                    </div>
                    {randomizedList?.map((l, index) => (
                        <div className={classes.formGroup} key={index}>
                            <div className={classes.inputLabel}>{l?.text}</div>
                            <div className={classes.radioContainer}>
                                <label className={classes.radioLabel}>
                                    <Radio
                                        checked={values?.find(x => x.id === l?.id)?.selectedAnswerId === firstOptionContainer?.id}
                                        onChange={handleCheck}
                                        value={firstOptionContainer?.id}
                                        name={firstOptionContainer?.text || ""}
                                        id={l?.id}
                                        disableRipple
                                        color="primary"
                                        disabled={showFeedback}
                                        icon={<span className={classes.radioIcon}></span>}
                                        checkedIcon={<span className={classes.radioIconChecked}></span>}
                                    />
                                </label>
                                <label className={classes.radioLabel}>
                                    <Radio
                                        checked={values?.find(x => x.id === l?.id)?.selectedAnswerId === secondOptionContainer?.id}
                                        onChange={handleCheck}
                                        value={secondOptionContainer?.id}
                                        name={secondOptionContainer?.text || ""}
                                        inputProps={{ 'aria-label': 'A' }}
                                        id={l?.id}
                                        disableRipple
                                        color="primary"
                                        disabled={showFeedback}
                                        icon={<span className={classes.radioIcon}></span>}
                                        checkedIcon={<span className={classes.radioIconChecked}></span>}
                                    />
                                </label>
                                {showFeedback && showFeedbackLabel(l?.id)}
                            </div>
                        </div>
                    ))}
                </Grid>
            </Grid>
        </CustomLayout>
    )
}


export const multiRadioSelectFragment = graphql`
fragment multiRadioSelectFragment on ContentfulMultiRadioSelectAssessment {
    id
    name
    feedback {
      raw
    }
    firstOptionContainer {
      id
      image {
        id
      }
      title
      text
      list {
        id
        text
        title
        image {
          id
        }
        assessment_option_container {
          id
        }
      }
    }
    overview {
      raw
    }
    secondOptionContainer {
      id
      image {
        id
      }
      text
      title
      list {
        id
        text
        title
        image {
          id
        }
        assessment_option_container {
          id
        }
      }
    }
  }  
`
export default MultiRadioSelect;
