import { Checkbox, Grid, makeStyles } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { ContentfulMultiSelectAssessment } from "types/graphql-types"
import StyledButton, { ButtonType } from "src/components/buttons/styled-button";
import { useDispatch } from "react-redux";
import ContentfulRichText, { ContentfulRichTextType } from "src/contentful/contentful-rich-text";
import { correctAnswer, wrongAnswer } from "src/state/features/lms/lmsSlice";
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import { graphql } from "gatsby";
import { CustomLayout } from "../layout";
import { IFooterProps, IHeaderProps } from "../layout/custom-layout";

const useStyles = makeStyles(() => ({
    container: {
        height: "80vh"
    },
    button: {
        marginTop: "auto"
    },
    question: {
    },
    formGroup: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0 2em 0 1em",

        '&:nth-child(2n + 2)': {
            background: "#eee",
        }
    },
    inputLabel: {
        display: "block",
        fontFamily: "'Roboto Condensed'",
        fontSize: "1.4em",

        '&:nth-child(2n + 2)': {
            background: "#eee",
        }
    },
    radioContainer: {
        display: "flex",
        alignItems: "center",
    },
    radioLabel: {
        position: "relative",
        display: "inline-block",
        verticalAlign: "middle"
    },
    radioIcon: {
        display: "block",
        width: "0.75em",
        height: "0.75em",
        background: "#fff",
        border: "0.125em solid #DB5205",
        borderRadius: "0.125em",
        boxSizing: "border-box",
    },
    radioIconChecked: {
        display: "block",
        width: "0.75em",
        height: "0.75em",
        background: "#DB5205",
        border: "0.125em solid #DB5205",
        borderRadius: "0.125em",
        boxShadow: "0 0 0 0.08em #fff inset",
        boxSizing: "border-box",
    },
    correctIcon: {
        alignItems: "center",
        color: "green"
    },
    incorrectIcon: {
        alignItems: "center",
        color: "red"
    },
    feedback: {
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "2em",
        height: "2.7em",
        marginRight: "-2em",
    },
}))

interface IProps {
    data: ContentfulMultiSelectAssessment
    handleNextSection: () => void
    debriefTitle?: string
    headerProps: IHeaderProps
}

const MultiSelect = ({ data, handleNextSection, debriefTitle, headerProps }: IProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { overview, options, feedback, id } = data;
    const [showFeedback, setShowFeedback] = useState(false);
    const [values, setValues] = useState<{ id: string }[]>([]);

    useEffect(() => {
        setShowFeedback(false);
        setValues([]);
    }, [id])

    function handleCheck(e: React.ChangeEvent<HTMLInputElement>) {
        let valueCopy = [...values];
        const currentId = e.target.name;
        const findItem = valueCopy.findIndex(x => x.id === currentId);
        if (findItem !== -1) valueCopy = valueCopy.filter(x => x.id !== currentId);
        else valueCopy?.push({ id: currentId });

        setValues(valueCopy);
    }

    function showFeedbackLabel(id: string | undefined) {
        if (id) {
            const isCorrect = options?.find(x => x?.id === id)?.correctAnswer;
            const isChecked = values.find(x => x.id === id);
            if ((isChecked && isCorrect) || (!isChecked && !isCorrect)) return <span className={classes.feedback}><DoneIcon className={classes.correctIcon} /></span>
            return <span className={classes.feedback}><CloseIcon className={classes.incorrectIcon} /></span>
        }
        return null;
    }

    function handleSubmit() {
        if (showFeedback) {
            handleNextSection();
        }
        else {
            let correctCounter = 0;
            options?.forEach(o => {
                const isChecked = values?.find(x => x?.id === o?.id);
                if (isChecked && o?.correctAnswer) {
                    correctCounter++;
                } else if (!isChecked && !o?.correctAnswer) {
                    correctCounter++;
                }
            });

            if (options) {
                const assessmentScore = Math.round((correctCounter / options?.length) * 100);
                const passed = assessmentScore >= 75;
                if (passed) dispatch(correctAnswer());
                else dispatch(wrongAnswer());
            }
            if (feedback) {
                setShowFeedback(true);
            }
        }
    }


    const footerProps: IFooterProps = {
        submitButton: {
            onClickHandler: handleSubmit,
            type: ButtonType.CONTINUE,
            isDisabled: values?.length === 0
        },
        allowSkip: false,
    }

    const debriefHeaderProps = {
        ...headerProps,
        title: debriefTitle || headerProps.title
    }

    return (
        <CustomLayout
            headerProps={showFeedback ? debriefHeaderProps : headerProps}
            footerProps={footerProps}
        >
            <Grid container spacing={0} alignItems="center" justify="center">
                <Grid item xs={12} className={classes.question}>
                    {showFeedback ?
                        <ContentfulRichText {...feedback as unknown as ContentfulRichTextType} />
                        :
                        <ContentfulRichText {...overview as unknown as ContentfulRichTextType} />
                    }
                </Grid>
                <Grid item xs={12}>
                    {options && options.map((l: any, index: number) => {
                        return (
                            <div className={classes.formGroup} key={index}>
                                <div className={classes.inputLabel}>{l?.label}</div>
                                <div className={classes.radioContainer} key={index}>
                                    <Checkbox
                                        checked={values.some(x => x.id == l?.id)}
                                        onChange={handleCheck}
                                        value={l?.id}
                                        name={l?.id || ""}
                                        disableRipple
                                        color="primary"
                                        disabled={showFeedback}
                                        icon={<span className={classes.radioIcon}></span>}
                                        checkedIcon={<span className={classes.radioIconChecked}></span>}
                                    />
                                    {showFeedback && showFeedbackLabel(l?.id)}
                                </div>
                            </div>
                        )
                    })}
                </Grid>
            </Grid>
        </CustomLayout>
    )
}



export const multiSelectFragment = graphql`
fragment multiSelectFragment on ContentfulMultiSelectAssessment {
    id
    name
    feedback {
      raw
    }
    overview {
      raw
    }
    title
    options {
      id
      correctAnswer
      title
      label
    }
  }
`

export default MultiSelect;