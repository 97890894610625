import React, { ChangeEvent } from 'react';
import { makeStyles,  Radio } from '@material-ui/core';

const useStyles = makeStyles(theme=>({
    root: {
        padding: 0,
        color: "inherit",

        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
}));

interface IProps {
    checked?: boolean
    name: string
    radioLabel?: string
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void
}

interface IconProps {
    label?: string
    checked?: boolean
}
const RadioIcon: React.FC<IconProps> = ({ label, checked}) => {
    return (null)
}

const RadioButton: React.FC<IProps> = ({ radioLabel, checked, ...props }) => {
    const classes = useStyles();
    return (
        <Radio
            className={classes.root}
            disableRipple
            color="primary"
            checkedIcon={<RadioIcon label={radioLabel} checked={checked} />}
            icon={<RadioIcon label={radioLabel} checked={checked} />}
            {...props}
        />
    );
}

export default RadioButton;