import { Grid, makeStyles } from "@material-ui/core"
import React, { ChangeEvent, useEffect, useState } from "react"
import { MultipleChoiceData, RadioOption } from "src/type/globals";
import { ContentfulMultipleChoiceAssessmentOption, ContentfulMultipleChoiceQuestion, ContentfulMultipleChoiceQuestionOptionSelectable } from "types/graphql-types"
import Feedback from "src/components/feedback/feedback";
import { useDispatch } from "react-redux";
import { NestedRadioContainer } from "src/components/form";
import { correctAnswer, wrongAnswer } from "src/state/features/lms/lmsSlice";
import ContentfulRichText, { ContentfulRichTextType } from "src/contentful/contentful-rich-text";
import { AssessmentButton } from "src/components/buttons";
import { useSurveyDataGetter } from "src/hooks/useDataGetter";
import CustomLayout, { IFooterProps, IHeaderProps } from "src/components/layout/custom-layout";
import { ContentfulMultipleChoiceType, MultipleChoiceAssessmentType } from "./multiple-choice";
import DropDown from "src/components/form/dropdown";
import { ButtonType } from "src/components/buttons/styled-button";
import { AssessmentType } from "src/client-pages/course/course-assessment";
const useStyles = makeStyles(() => ({
  button: {
    marginTop: "auto"
  },
  question: {
  }
}))
interface KeyValue {
  [key: string]: any
}
const OptionType: KeyValue = {
  "ContentfulMultipleChoiceAssessmentOption": "Assessment",
  "ContentfulMultipleChoiceQuestionOptionSelectable": "Selectable",
  "ContentfulMultipleChoiceQuestionOptionTextInput": "TextInput",
  "ContentfulMultipleChoiceQuestionOptionContainer": "Container"
}

interface IProps {
  data: ContentfulMultipleChoiceQuestion
  section: string
  handleNextSection: () => void
  debriefTitle: string
  headerProps: IHeaderProps
}

const MultipleChoiceWithFeedback = ({ data, handleNextSection, debriefTitle, section, headerProps }: IProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { id, name, optional, appearance, question, options, correctDebrief, wrongDebrief } = data as ContentfulMultipleChoiceType;

  const surveySectionData = useSurveyDataGetter(`${section}.${id}`);
  const componentData: MultipleChoiceData = surveySectionData ? surveySectionData.data as MultipleChoiceData : { radioAnswer: "", textAnswer: "" }

  const [radioAnswer, setRadioAnswer] = useState(componentData.radioAnswer);
  const [textAnswer, setTextAnswer] = useState(componentData.textAnswer);
  const [showFeedback, setShowFeedback] = useState(false);
  const [isCorrect, setIsCorrectAnswer] = useState(false);

  useEffect(() => {
    setShowFeedback(false);
    setRadioAnswer("");
    setTextAnswer("");
    setIsCorrectAnswer(false);
  }, [id])

  function handleFeedback(e?: any) {
    let isCorrectAnswer = false;
    if (radioAnswer != "") {
      const option = options?.find(x => x?.label === radioAnswer) as ContentfulMultipleChoiceQuestionOptionSelectable;
      isCorrectAnswer = option?.isCorrectAnswer!;
    } else {
      const option = options?.find(x => x?.label === e) as ContentfulMultipleChoiceQuestionOptionSelectable;
      isCorrectAnswer = option?.isCorrectAnswer!;
    }

    if (isCorrectAnswer)
      dispatch(correctAnswer())
    else
      dispatch(wrongAnswer())

    setIsCorrectAnswer(isCorrectAnswer);
    setShowFeedback(true);
  }

  function handleAnswerSelect(e: ChangeEvent<HTMLInputElement>) {
    setTextAnswer("");
    setRadioAnswer(e.target.value);
  }

  function handleInputChange(e: ChangeEvent<HTMLInputElement>) {
    setTextAnswer(e.target.value);
  }
  function handleBinarySelect(e: any) {
    handleFeedback(e);
  }

  const footerProps: IFooterProps = {
    submitButton: {
      onClickHandler: handleFeedback,
      type: ButtonType.CONTINUE,
      isDisabled: radioAnswer === ""
    },
    allowSkip: !!optional,
    skipButton: {
      onClickHandler: handleNextSection
    }
  }

  const debriefHeaderProps = {
    ...headerProps,
    title: debriefTitle || headerProps.title
  }

  if (showFeedback) {
    return (
      <Feedback
        headerProps={debriefHeaderProps}
        handleNextSection={handleNextSection}
        data={isCorrect ? correctDebrief : wrongDebrief}
      />
    )
  }


  if (options?.length === 2) {
    return (
      <CustomLayout
        headerProps={headerProps}
      >
        <Grid container spacing={0} alignItems="center" justify="center" style={{ height: '100%' }}>
          {question &&
            <Grid container style={{ alignSelf: "flex-start" }}>
              <Grid item xs={12} className={classes.question} >
                <ContentfulRichText {...question as unknown as ContentfulRichTextType} />
              </Grid>
            </Grid>
          }
          <Grid container spacing={0} className={classes.button} alignItems="center" justify="center" direction="row">
            <Grid item xs={5} sm={5} >
              {options[0] &&
                <AssessmentButton
                  label={options[0]?.label!}
                  onClickHandler={() => handleBinarySelect(options[0]?.label!)}
                />
              }
            </Grid>
            <Grid item xs={2} sm={2} style={{ textAlign: 'center' }}>
              Or
              </Grid>
            <Grid item xs={5} sm={5} >
              {options[1] &&
                <AssessmentButton
                  label={options[1]?.label!}
                  onClickHandler={() => handleBinarySelect(options[1]?.label!)}
                />
              }
            </Grid>
          </Grid>
        </Grid>
      </CustomLayout>
    )
  }

  return (
    <CustomLayout
      headerProps={headerProps}
      footerProps={footerProps}
    >
      <Grid container spacing={0} alignItems="center" justify="center">
        {question &&
          <Grid item xs={12} className={classes.question} >
            <ContentfulRichText {...question as unknown as ContentfulRichTextType} />
          </Grid>
        }
        <Grid item xs={12}>
          {appearance === "List" &&
            <NestedRadioContainer
              name={name!}
              textAnswer={textAnswer}
              handleInputChange={handleInputChange}
              handleAnswerSelect={handleAnswerSelect}
              options={options?.map(o => {
                const option = o as MultipleChoiceAssessmentType;
                return {
                  id: option?.id,
                  typename: OptionType[option?.__typename as string],
                  label: option?.label,
                  value: option?.label,
                  containerOptions: option.__typename == "ContentfulMultipleChoiceQuestionOptionContainer" ? option?.listOfOptions : null
                } as RadioOption
              })}
            />
          }
          {appearance === "Dropdown" &&
            <DropDown
              name={name!}
              handleAnswerSelect={handleAnswerSelect}
              options={options?.map(o => {
                const option = o as MultipleChoiceAssessmentType;
                if (OptionType[option?.__typename as string] === "Selectable")
                  return {
                    id: option?.id,
                    typename: OptionType[option?.__typename as string],
                    label: option?.label,
                    value: option?.label,
                    containerOptions: null
                  } as RadioOption
              })}
            />
          }
        </Grid>
      </Grid>
    </CustomLayout>
  )
}


export default MultipleChoiceWithFeedback;
