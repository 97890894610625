import { useMemo } from "react";
import { useSelector } from "react-redux";
import { IApplicationState } from "src/state/store";

export type ParametrizedSelector<A, R> = (state: IApplicationState, arg: A) => R;

export const proxyParam: <T>(_: IApplicationState, param: T) => T = (_, param) => param;

export function useParamSelector<A, R>(selectorCreator: () => ParametrizedSelector<A, R>, argument: A): R {
    const memoizedSelector = useMemo(() => {
        const parametrizedSelector = selectorCreator();
        return (state: IApplicationState) => parametrizedSelector(state, argument);
    }, [typeof argument === 'object' ? JSON.stringify(argument) : argument]);
    return useSelector(memoizedSelector);
}
